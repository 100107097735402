import {useState} from 'react'
import mobileThxBackground from '../../../content/media/kot_kontakt-thankyou-m.jpg'
import desktopThxBackground from '../../../content/media/kot_kontakt-thankyou-d.jpg'
import Appshell from '../../components/Appshell'
import Background from '../../uikit/Background'
import mobileBackground from '../../assets/mobile-background.jpg'
import desktopBackground from '../../assets/desktop-background.jpg'
import Text from '../../uikit/Text'
import Flex from '../../uikit/Flex'
import Button from '../../uikit/Button'
import useForm from '../../hooks/useForm'
import ThankYouPayment from '../../components/screens/ThankYouPayment'
import TextInput from '../../uikit/form/TextInput'
import Checkbox from '../../uikit/form/Checkbox'
import encode from '../../utils/encode'


const text = {
  title: 'obnovenie predplatného',
  description: `Ak si prajete opäť obnoviť predplatné, ktoré ste mali naposledy, či pokračovať v predplatnom,
  ktoré ste naposledy zrušili, je to veľmi jednoduché. Zadajte e-mail, ktorý ste použili naposledy a vyberte
  požadovanú možnosť:`,
}

const Renewal = () => {
  const {values, handleChange} = useForm()
  const [isSubmitted, setSubmitted] = useState(false)

  const handleSubmit = (e) => {
    e.preventDefault()

    fetch('/', {
      method: 'POST',
      body: encode({
        'form-name': 'renewal',
        ...values,
      }),
    })
      .then(() => {
        setSubmitted(true)
      })
      .catch((error) => console.error(error))
  }

  if (isSubmitted) {
    return (
      <Appshell>
        <Background desktopBackground={desktopThxBackground} mobileBackground={mobileThxBackground}>
          <ThankYouPayment />
        </Background>
      </Appshell>
    )
  }
  return (
    <Appshell>
      <Background desktopBackground={desktopBackground} mobileBackground={mobileBackground}>
        <Flex column>
          <Text component="h1" textStyle="h1Alternate">
            {text.title}
          </Text>
          <p>
            {text.description}
          </p>
          <form
              name="renewal"
              method="post"
              action="/"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
              onSubmit={handleSubmit}
          >
            <input type="hidden" name="form-name" value="renewal" />
            <Flex column>
              <Checkbox
                  label="obnoviť minulé predplatné"
                  name="renew_last"
                  value={values?.renew_last}
                  onChange={handleChange}
              />
              <Checkbox
                  label="dokončiť zrušené predplatné"
                  name="renew_canceled"
                  value={values?.renew_canceled}
                  onChange={handleChange}
              />
              <TextInput
                  type="email"
                  name="email"
                  placeholder="Váš email"
                  value={values?.email}
                  onChange={handleChange}
                  required
              />
              <Flex column crossAxis="center">
                <p>
                  To je všetko. Je skvelé, že ste späť! Môžete
                </p>
                <Button type="submit">
                  Odoslať
                </Button>
              </Flex>
            </Flex>
          </form>
        </Flex>
      </Background>
    </Appshell>
  )
}

export default Renewal
